import React from 'react'
import Link from "./Link"
import InstaLogoOriginal from '../images/insta.svg'
import styled from 'styled-components'

const InstaLink = styled('a')`
    display: inline-block;
    text-decoration: none;
    color: black;
    font-weight: bold;
    transform: scale(1);
    max-width: 36px;
    @media (max-width: 640px) {
      order: 2;
      margin-left: 1em;
      max-width: 28px;
      position: relative;
      top: -0.492em;
    }
    span {
      display: inline-block;
      font-size: 0.8em;
      display: inline-block;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.7);
      font-weight: bold;
      margin-left: 6px;
    }
    img {
      display: block;
      width: 100%;
      margin: 0;
      position: relative;
    }
`

const Insta = ({ text, bottom }) => (
  <>
    <InstaLink href="https://www.instagram.com/micahbrich/" target="_blank">
        <img src={InstaLogoOriginal} />
        {text && <span>instagram.com/micahbrich</span>}
    </InstaLink>
  </>
)

export default Insta
