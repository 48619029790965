import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import styled from 'styled-components'


const Link = styled('a')`
  font-size: 1rem;
  opacity: 0.9;
  text-decoration: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 300ms ease-in;
  text-decoration: none;
  color: white;
  text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.24),
    0 0 1rem rgba(0, 0, 0, 0.24);
  &:hover {
    opacity: 1;
  }
`
export const InternalLink = Link.withComponent(LinkComponent)
export default Link
