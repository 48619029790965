import { InternalLink } from './Link'
import PropTypes from 'prop-types'
import React from 'react'
import styled from "styled-components"
import Insta from './Insta'

const Bar = styled('header')`
    display: flex;
    min-height: 2em;
    position: fixed;
    @media (max-width: 640px) {
      position: relative;
      background: rgba(0,0,0,0.9);
    }
    z-index: 100;
    top: 0;
    left: 0;
    padding: 1.618rem;
    text-align: right;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    span {
      opacity: 0.7;
      font-weight: normal;
    }
    * {
      height: 100%;
    }
    .back {
      text-align: left;
    }
    a {
      flex: 1;
      padding: 0.192rem;
      display: inline-block;
      z-index: 100;
    }
`

const Header = ({ siteTitle, place }) => (
  <Bar>
        <Insta/>
        <InternalLink to="/">
          micah rich
          { place && <span> in </span>}
          { place }
          <span> • travel photographer & videographer</span>
        </InternalLink>
  </Bar>
)

export default Header
