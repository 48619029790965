import React from 'react'
import Insta from "./Insta"
import Img from "gatsby-image"
import NewsletterForm from "./NewsletterForm"
import styled from 'styled-components'
import micah from "../images/micah.jpg"
import { StaticQuery, graphql } from "gatsby"

const Footer = styled('footer')`

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    line-height: 1.5;

  .gatsby-image-wrapper {
    flex: 1;
    min-width: 30%;
    @media (max-width: 640px) {
      min-width: 100%;
    }
  }
  aside {
    flex: 3;
    display: block;
    padding: 2em 6vw;
    width: auto;
  }
  aside h2 {
    font-weight: 800;
    font-size: 3.3rem;
    line-height: 1.3;
    margin: 0 0 0.192em 0;
    color: rgba(0, 0, 0, 0.8);
  }
  aside h4 {
    font-size: 1rem;
    margin: 0 0 1.618em 0;
    line-height: 1.3;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.8);
  }
  a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
  }
  aside p {
    margin: 1em 0 0 0;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
    max-width: 600px;
  }
`

const FooterComponent = props => (
  <Footer>
    <Img fluid={props.micah.childImageSharp.fluid}/>
    <aside>
      <h2>micah rich</h2>
      <p>
        a photographer & videographer for nearly 15 years, using
        digital photography, filmmaking & a unique cinematic take to travel photography.
      </p>
      <br/>
      <NewsletterForm/>
      <p>
        To follow along or reach out, find him on instagram
        (<a href="https://www.instagram.com/micahbrich/">@micahbrich</a>) or
        add your name & email to the newsletter above.
      </p>
    </aside>
  </Footer>
)

const query = graphql`
  query FooterQuery {

    micah: file(relativePath: {eq: "micah.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

  }
`

export default () => (
  <StaticQuery
    query={query}
    render={data => <FooterComponent {...data}/>}
  />
)
