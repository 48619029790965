import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled('div')`
  form {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    width: 100%;
    margin: 1em 0;
    div {
      width: 100%;
      flex: 1;
      align-self: flex-end;
      margin: 0.192em;
      @media (max-width: 640px) {
        flex: none;
        display: block;
        width: 100%;
      }
      label {
        text-transform: lowercase;
        color: rgba(0,0,0,0.7);
        display: block;
        font-weight: normal;
        margin: 0.392em;
      }
    }
    div[aria-hidden="true"] {
      display: none;
    }
    button, input[type='submit'] {
      cursor: pointer;
      font-weight: bold;
      background: rgba(0,0,0,0.8);
      color: white;
      @media (max-width: 640px) {
        margin: 1.618em 0;
      }
    }
    button, input {
      @media (max-width: 640px) {
        width: 100%;
      }
       -webkit-appearance: none;
       -moz-appearance: none;
       appearance: none;
       font-size: 0.918rem;
       padding: 0.392em 0.618em;
       border: 1px solid rgba(0,0,0,0.3);
       border-radius: 4px;
    }
  }
`

const NewsletterForm = props => (

  <Wrapper>


    <form action="https://micahrich.us19.list-manage.com/subscribe/post?u=9dbca66e118734f46f2c7a5a9&amp;id=b27cf27c0e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>

      <div className="mc-field-group">
        <label htmlFor="mce-EMAIL">Email Address </label>
        <input type="email" name="EMAIL" className="required email" id="mce-EMAIL"/>
      </div>

      <div className="mc-field-group">
        <label htmlFor="mce-FNAME">First Name </label>
        <input type="text" name="FNAME" className="required" id="mce-FNAME"/>
      </div>

      <div css={`position: absolute; left: -5000px;`} aria-hidden="true">
        <input type="text" name="b_9dbca66e118734f46f2c7a5a9_b27cf27c0e" tabIndex="-1"/>
      </div>

      <div className="clear">
        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>
      </div>

    </form>


  </Wrapper>

)

export default NewsletterForm
